export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const API_VERSION = process.env.REACT_APP_API_VERSION;
export const X_API_KEY = process.env.REACT_APP_X_API_KEY;
export const SOCKET_URL = process.env.REACT_APP_SOCKET_URL;

export const GAME_LINK = process.env.REACT_APP_GAME_LINK;

export const API_URLS = {
  LOGIN: API_BASE_URL + API_VERSION + "/login",
  LOGOUT: API_BASE_URL + API_VERSION + "/logout",
  REGISTER: API_BASE_URL + API_VERSION + "/user/register",
  CHANGEPASSWORD: API_BASE_URL + API_VERSION + "/user/password/change",
  RESETPASSWORD: API_BASE_URL + API_VERSION + "/user/password/reset",
  FORGOTPASSWORD: API_BASE_URL + API_VERSION + "/user/password/forgot",
  VERIFY_OTP : API_BASE_URL + API_VERSION + "/user/verifyOTP",
  CATEGORIES : API_BASE_URL + API_VERSION + "/game/category",
  PROVIDERS : API_BASE_URL + API_VERSION + "/game/providers",
  GAMES : API_BASE_URL + API_VERSION + "/game",
  USER : API_BASE_URL + API_VERSION + "/user",
  BANKACCOUNT :  API_BASE_URL + API_VERSION + "/bankAccount",
  TRANSACTIONS : API_BASE_URL + API_VERSION + "/transaction/list",
  TRANSACTION : API_BASE_URL + API_VERSION + "/user/transaction",
  CANCEL : API_BASE_URL + API_VERSION + "/user/transaction/cancel",
  PROMOTIONS : API_BASE_URL + API_VERSION + "/promotions",
  GAMELINK : API_BASE_URL + API_VERSION + "/game/launch",
  UPDATEUSER: API_BASE_URL + API_VERSION + "/user/update",
  ACCOUNT_TYPES :  API_BASE_URL + API_VERSION + "/account/type",
  ACCOUNT_BRANDS :  API_BASE_URL + API_VERSION + "/account/brand",
  CONTACTS :  API_BASE_URL + API_VERSION + "/contacts",
  CMS_BANKS :  API_BASE_URL + API_VERSION + "/account/list",
  MAIN_BANNER : API_BASE_URL + API_VERSION + "/mainBanner",
  POPUP_BANNER : API_BASE_URL + API_VERSION + "/popupBanner",
  RUNNING_TEXT : API_BASE_URL + API_VERSION + "/runningText",
  FOOTER : API_BASE_URL + API_VERSION + "/footer",
  HOW_IT_WORKS : API_BASE_URL + API_VERSION + "/howItWorkSection",
  SITE : API_BASE_URL + API_VERSION + "/site",
  WHITE_LABEL_DATA : API_BASE_URL + API_VERSION + "/whiteLabel/data",
  BONUS_TRANSACTIONS : API_BASE_URL + API_VERSION + "/transaction/bonus",
  REFERRAL_LIST : API_BASE_URL + API_VERSION + "/referral/list",
};

export const STATUS_CODES = {
  SUCCESS: 200,
  UNAUTHORIZED: 401,
  BAD_REQUEST: 400,
};

export const LANGUAGE = {
  ENGLISH: 'en',
  INDONESIAN: 'in'
};
