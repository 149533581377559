import React, { useState } from "react";
import { Button, InputFieldFormik } from "Components";
import { changePassword } from "Redux/Actions/Auth";
import { ROUTES } from "Routes/constants";
import { STATUS_CODES } from "Services/Api/Constants";
import { IMAGES } from "Shared/Images";
import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import ClientCaptcha from "react-client-captcha";
import useShowResponse from "Shared/ShowResponse";

const ChangePassword = () => {
  const { t } = useTranslation();
  const showResponse = useShowResponse();

  const validate = Yup.object({
    oldPassword: Yup.string()
      .required(t("required"))
      .matches(/^(?=.*[a-zA-Z!@#\$%\^&\*])(?=.*[0-9]).{6,}$/, t("password-val")),
    newPassword: Yup.string()
      .required(t("required"))
      .matches(/^(?=.*[a-zA-Z!@#\$%\^&\*])(?=.*[0-9]).{6,}$/, t("password-val")),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("newPassword"), null], t("password-match"))
      .required(t("required")),
  });

  const history = useHistory();
  const dispatch = useDispatch();
  const [code, setCode] = useState("");
  const [error, setError] = useState();
  const [showPassword, setShowPassword] = useState({
    oldPassword: false,
    newPassword: false,
    confirmPassword: false,
  });

  const handleSubmit = (value) => {
    if (error.length) {
      return;
    }

    let payload = { ...value };
    delete payload.confirmPassword;
    delete payload.validationCode;
    dispatch(
      changePassword(payload, (response) => {
        showResponse({
          key: response.msg,
          variant: response.statusCode === STATUS_CODES.SUCCESS ? "success" : "error",
          vertical: 'top',
          horizontal: 'right'
        });

        if (response.statusCode === STATUS_CODES.SUCCESS) {
          history.push(ROUTES.HOME);
        }
      })
    );
  };
  return (
    <div className="w-100 d-flex align-items-center justify-content-center w-100 form_front min-vh-60 register-secn">
      <div className="container py-md-5 pt-4">
        <div className="form_card bg-white text-start p-4">
          <Formik
            onSubmit={handleSubmit}
            validationSchema={validate}
            initialValues={{
              newPassword: "",
              oldPassword: "",
              confirmPassword: "",
              validationCode: "",
            }}
          >
            {(props) => (
              <>
                <Form
                  className="form_pad"
                  onChange={(e) => {
                    if (e?.target?.name == "validationCode") {
                      setError(e.target.value == code ? "" : t("code-match"));
                    }
                  }}
                >
                  <div className="page_title mb-4">
                    <h1 className="h4 mb-0">{t("change-password")}</h1>
                  </div>
                  <div className="row g-3 ">
                    <div className="col-12">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group password-view">
                            <InputFieldFormik
                              name={"oldPassword"}
                              label={t("old-password")}
                              type={showPassword.oldPassword ? "text" : "password"}
                              required={true}
                            />
                            <span
                              className="pwd-view-img"
                              onClick={(prev) => setShowPassword({ ...prev, oldPassword: !showPassword.oldPassword })}
                            >
                              <img
                                src={showPassword.oldPassword ? IMAGES.eyeOpen : IMAGES.eyeClose}
                                className="img-fluid"
                                alt="imgOpen"
                              />
                            </span>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group password-view">
                            <InputFieldFormik
                              name={"newPassword"}
                              label={t("new-password")}
                              type={showPassword.newPassword ? "text" : "password"}
                              required={true}
                            />
                            <span
                              className="pwd-view-img"
                              onClick={(prev) => setShowPassword({ ...prev, newPassword: !showPassword.newPassword })}
                            >
                              <img
                                src={showPassword.newPassword ? IMAGES.eyeOpen : IMAGES.eyeClose}
                                className="img-fluid"
                                alt="imgOpen"
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group password-view">
                            <InputFieldFormik
                              name={"confirmPassword"}
                              label={t("verify-password")}
                              type={showPassword.confirmPassword ? "text" : "password"}
                              required={true}
                            />
                            <span
                              className="pwd-view-img"
                              onClick={(prev) =>
                                setShowPassword({ ...prev, confirmPassword: !showPassword.confirmPassword })
                              }
                            >
                              <img
                                src={showPassword.confirmPassword ? IMAGES.eyeOpen : IMAGES.eyeClose}
                                className="img-fluid"
                                alt="imgOpen"
                              />
                            </span>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <div className="d-flex justify-content-between align-items-center validation_row">
                              <div className="valdiation_box">
                                <InputFieldFormik
                                  name={"validationCode"}
                                  label={t("validation-code")}
                                  type={"text"}
                                  error={error?.validation?.length ? true : false}
                                  required={true}
                                />
                              </div>
                              <div className="captcha-box">
                                <ClientCaptcha captchaCode={(code) => setCode(code)} required={true} chars="abcdefghijklmnopqrstuvwxyz0123456789"/>
                              </div>
                            </div>
                            {error ? <span className="error d-block">{error}</span> : ""}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 text-center text-md-end common_btn mt-md-5">
                      <Button className="btn btn-lg btn-primary" type="submit">
                        {t("change")}
                      </Button>
                    </div>
                  </div>
                </Form>
              </>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
