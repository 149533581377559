/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useMemo, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import "./style.scss";

import { Button, LoginModal, WelcomeModal } from "Components/Atoms";
import { logout } from "Redux/Actions/Auth";
import { IMAGES } from "Shared/Images";
import ForgotModal from "Components/Atoms/ForgotModal";
import CheckClickOutside from "Hooks/OutSideClick";
import { getUserDetails, setUserDetails, setUserLang, updateUserDetails } from "Redux/Actions/user";
import { ROUTES } from "Routes/constants";
import { updateAuthToken } from "Shared/Axios";
import { LANGUAGE } from "Services/Api/Constants";
import { NAVIGATION_BAR, SOCKET_EVENTS } from "Shared/Constants";
import { fetchFooterText, fetchRunningText, fetchWelcomeCmsContent } from "Redux/Actions/CMS";
import { SocketManager } from "Services/SocketManager";
import { setSocket, setSocketManager } from "Redux/Actions/Socket";
import { getWhiteLabelData } from "Redux/Actions/common";

//Making socket instance
const socketInstance = new SocketManager();

const navLinks = [
  { name: "home", path: ROUTES.HOME, index: 1 },
  { name: "promotions", path: ROUTES.PROMOTION, index: 2 },
  { name: "games", path: ROUTES.GAMES, index: 3 },
  { name: "howitworks", path: ROUTES.HOWITWORKS, index: 4 },
];

const AppLayout = ({ children }) => {
  const { i18n, t } = useTranslation();
  const loginModal = useSelector((state) => state.user.loginModal);
  const token = useSelector((state) => state.auth.token);
  const lang = useSelector((state) => state.user.lang);
  const userDetails = useSelector((state) => state.user.details);
  const [openLoginModal, setOpenLoginModal] = useState(false);
  const [openForgotModal, setOpenForgotModal] = useState(false);
  const [showDD, setShowDD] = useState(false);
  const [langDD, setLangDD] = useState(false);
  const [expandNav, setExpandNav] = useState(false);
  const [active, setActive] = useState(1);
  const history = useHistory();
  const location = useLocation();

  const dispatch = useDispatch();

  const prevRoute = useRef(location.pathname);

  const [language, setLanguage] = useState("en");

  const runningText = useSelector((state) => state?.cms?.runningText || []);
  const socket = useSelector((state) => state?.socket?.socket);
  const whiteLabelData = useSelector(state => state?.common?.whiteLabelData || {});


  // Toggle
  const [isToggled, setIsToggled] = useState(false);

  useEffect(() => {
    const body = document.querySelector("body");
    if (isToggled) {
      body.classList.add("menu_active");
    } else {
      body.classList.remove("menu_active");
    }
  }, [isToggled]);

  useEffect(() => {
    setOpenLoginModal(loginModal || false);
  }, [loginModal]);

  useEffect(() => {
    setActive(getActive(location?.pathname));
    prevRoute.current = location.pathname;
  }, [location]);

  useEffect(() => {
    dispatch(setSocketManager(socketInstance));
    if (token) {
      socketInstance.establishConnection(token, (socket) => dispatch(setSocket(socket)));
    }
    return () => {
      socketInstance.disconnectConnection();
      dispatch(setSocket(""));
    };
  }, []);

  useEffect(() => {
    if (socket) {
      socketInstance.onListenEvent(SOCKET_EVENTS.USER_BALANCE, (response) => {
        if (response?.balance !== undefined) {
          let tempUserDetails = { ...userDetails };
          tempUserDetails.credit = response.balance;
          dispatch(setUserDetails(tempUserDetails));
        } else {
          dispatch(getUserDetails());
        }
      });
    }
  }, [socket]);

  useEffect(() => {
    dispatch(fetchWelcomeCmsContent());
    dispatch(fetchRunningText());
    dispatch(fetchFooterText());
    dispatch(getWhiteLabelData());

    if (token) {
      updateAuthToken(token);
      dispatch(getUserDetails());
    }
    return () => {
      setShowDD(false);
    };
  }, []);

  useEffect(() => {
    setLanguage(lang);
    i18n.changeLanguage(lang);
  }, [lang]);

  const toggleBodyClass = () => {
    setIsToggled(!isToggled);
  };

  const logoutHandler = () => {
    dispatch(logout());
    setShowDD(false);
  };

  const changeRoute = (page, index) => {
    history.push(page);
    setActive(index);
    // dispatch(setActiveScreen(index))
    // hideMenu();
    setExpandNav(false);
  };

  const getActive = (value) => {
    const activeIndex = navLinks.find((item) => item.path == value);
    return activeIndex ? activeIndex.index : '';
  };

  const handleLanguageChange = (selected = LANGUAGE.ENGLISH) => {
    setLanguage(selected);
    i18n.changeLanguage(selected);
    dispatch(setUserLang(selected));
    if (token) {
      let payload = { ...userDetails };
      payload.userId = userDetails._id;
      payload.language = selected;
      delete payload._id;
      delete payload.credit;
      delete payload.userType;
      delete payload.userName;
      delete payload.status;
      dispatch(updateUserDetails(payload));
    }
    setLangDD(false);
  };

  const handleCreditRefresh = () => {
    if (socket) {
      socket.emit(SOCKET_EVENTS.USER_BALANCE, {}, (response) => {
        if (response.success) {
          let tempUserDetails = { ...userDetails };
          tempUserDetails.credit = response.balance;
          dispatch(setUserDetails(tempUserDetails));
        }
      });
    } else {
      dispatch(getUserDetails());
    }
  };

  const renderLinks = useMemo(() => {
    return navLinks.map((item, index) => {
      return (
        <li
          key={index}
          className="nav-item"
          onClick={() => (location.pathname == item.path ? "" : changeRoute(item.path, item.index))}
        >
          <a className={`nav-link ${active == item.index ? "active" : ""}`}>{t(item.name)}</a>
        </li>
      );
    });
  }, [active, location.pathname]);

  return (
    <>
      <header id="header">
        <nav className="navbar navbar-expand-lg navbar-light bg-Nav">
          <div className="container-fluid d-flex flex-nowrap">
            <div className="left_head d-flex align-items-center">
              <a
                className="navbar-brand p-0 mx-0"
                onClick={() => (location.pathname == ROUTES.HOME ? "" : changeRoute(ROUTES.HOME, 1))}
              >
                <img className="img-fluid" src={whiteLabelData?.brandImage || ""} alt="logo" width="300" />
              </a>
              <div className={`collapse navbar-collapse ${expandNav ? "show" : ""}`} id="navbarSupportedContent">
                <div className="d-lg-none px-2">
                  {token ? (
                    <div className="row justify-content-center">
                      <div className="col-6 mb-3">
                        <div className="dollar_box_item d-flex justify-content-between">
                          <span className="me-2">
                            {userDetails?.credit
                              ? parseInt(userDetails?.credit)
                                .toString()
                                .split(/(?=(?:\d{3})+(?:\.|$))/g)
                                .join(",")
                              : 0}
                          </span>
                          <button className="btn" type="submit" onClick={handleCreditRefresh}>
                            <img src={IMAGES.arrows_rotate} width="30" alt="" />
                          </button>
                        </div>
                      </div>
                      {/* <div className="col-6">
                      <CheckClickOutside onClick={() => setLangDD(false)}>
                        <div className="dropdown custom_dropdown user_Lang">
                          <button className="dropbtn" onClick={() => setLangDD(!langDD)}>
                            <img
                              src={language == "in" ? IMAGES.indonesiaflag : IMAGES.usflg}
                              width="30"
                              alt=""
                            />
                          </button>

                            <div className={`dropdown-menu dropdown-menu-end ${langDD ? "show" : ""}`}>
                              <a
                                className="dropdown-item"
                                onClick={() => handleLanguageChange(LANGUAGE.ENGLISH)}
                              >
                                <em className="me-2">
                                  <img src={IMAGES.usflg} alt="" width="30" />
                                </em>
                                <span>English</span>
                              </a>
                              <a
                                className="dropdown-item"
                                onClick={() => handleLanguageChange(LANGUAGE.INDONESIAN)}
                              >
                                <em className="me-2">
                                  <img src={IMAGES.indonesiaflag} alt="" width="30" />
                                </em>
                                <span>Indonesian</span>
                              </a>
                            </div>
                        </div>
                          </CheckClickOutside>
                      </div> */}
                    </div>
                  ) : (
                    null
                  )}
                </div>
                <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                  {renderLinks}
                </ul>
              </div>
            </div>

            <div className="right_header d-flex align-items-center">
              {/* <CheckClickOutside onClick={() => setLangDD(false)}>
              <div className="dropdown custom_dropdown user_Lang me-3 d-none d-lg-inline-block">
                <button className="dropbtn" onClick={() => setLangDD(!langDD)}>
                  <img
                    src={language == "in" ? IMAGES.indonesiaflag : IMAGES.usflg}
                    width="30"
                    alt=""
                  />
                </button>
                  <div className={`dropdown-menu dropdown-menu-end ${langDD ? "show" : ""}`}>
                    <a
                      className="dropdown-item"
                      onClick={() => handleLanguageChange(LANGUAGE.ENGLISH)}
                    >
                      <em className="me-2">
                        <img src={IMAGES.usflg} alt="" width="30" />
                      </em>
                      <span>English</span>
                    </a>
                    <a
                      className="dropdown-item"
                      onClick={() => handleLanguageChange(LANGUAGE.INDONESIAN)}
                    >
                      <em className="me-2">
                        <img src={IMAGES.indonesiaflag} alt="" width="30" />
                      </em>
                      <span>Indonesian</span>
                    </a>
                  </div>
              </div>
                </CheckClickOutside> */}

              {token ? (
                <>
                  <div className="d-flex">
                    <button className="border border-0 bg-transparent" type="button" onClick={() => {
                      history.push(ROUTES.TRANSACTIONS);
                      setShowDD(false);
                    }}>
                      <img src={IMAGES.depositLogo} width="20" alt="plus" />
                    </button>
                    <span>|</span>
                    <button className="border border-0 bg-transparent" type="button" onClick={() => {
                      history.push({
                        pathname: ROUTES.TRANSACTIONS,
                        type: NAVIGATION_BAR.WITHDRAWAL,
                      });
                      setShowDD(false);
                    }}>
                      <img src={IMAGES.withdrawLogo} width="20" alt="plus" />
                    </button>
                  </div>
                  <div className="dollar_box_item d-md-flex justify-content-between ms-3 me-2">
                    <span className="me-2">
                      {userDetails?.credit
                        ? parseInt(userDetails?.credit)
                          .toString()
                          .split(/(?=(?:\d{3})+(?:\.|$))/g)
                          .join(",")
                        : 0}
                    </span>
                    <button className="btn" type="submit" onClick={handleCreditRefresh}>
                      <img src={IMAGES.arrows_rotate} width="30" alt="plus" />
                    </button>
                  </div>

                  <div className="dropdown custom_dropdown">
                    <button
                      className="btn dropdown-toggle"
                      onClick={() => setShowDD(!showDD)}
                      type="button"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {userDetails?.userName}
                    </button>
                    <CheckClickOutside onClick={() => setShowDD(false)}>
                      <div
                        className={`dropdown-menu dropdown-menu-end ${showDD ? "show" : ""}`}
                        aria-labelledby="dropdownMenuButton"
                      >
                        <a
                          className="dropdown-item"
                          onClick={() => {
                            history.push(ROUTES.TRANSACTIONS);
                            setShowDD(false);
                          }}
                        >
                          {t("deposit")}
                        </a>

                        <a
                          className="dropdown-item"
                          onClick={() => {
                            history.push({
                              pathname: ROUTES.TRANSACTIONS,
                              type: NAVIGATION_BAR.WITHDRAWAL,
                            });

                            setShowDD(false);
                          }}
                        >
                          {t("withdraw")}
                        </a>
                        <a
                          className="dropdown-item"
                          onClick={() => {
                            history.push({
                              pathname: ROUTES.TRANSACTIONS,
                              type: NAVIGATION_BAR.LAST_TRANSACTIONS,
                            });

                            setShowDD(false);
                          }}
                        >
                          {t("transactions")}
                        </a>
                        <a
                          className="dropdown-item"
                          onClick={() => {
                            history.push({
                              pathname: ROUTES.TRANSACTIONS,
                              type: NAVIGATION_BAR.BONUS_TRANSACTIONS,
                            });

                            setShowDD(false);
                          }}
                        >
                          {t("bonuses-transactions")}
                        </a>
                        <a
                          className="dropdown-item"
                          onClick={() => {
                            history.push({
                              pathname: ROUTES.TRANSACTIONS,
                              type: NAVIGATION_BAR.REFERRAL_LIST,
                            });

                            setShowDD(false);
                          }}
                        >
                          {t("referral-list")}
                        </a>
                        <a
                          className="dropdown-item"
                          onClick={() => {
                            history.push(ROUTES.CHANGE_PASSWORD);
                            setShowDD(false);
                          }}
                        >
                          {t("change-password")}
                        </a>
                        <a
                          className="dropdown-item"
                          onClick={() => {
                            history.push(ROUTES.REFERRAL);
                            setShowDD(false);
                          }}
                        >
                          {t("referral-link")}
                        </a>
                        <a className="dropdown-item" onClick={logoutHandler}>
                          {t("logout")}
                        </a>
                      </div>
                    </CheckClickOutside>
                  </div>
                </>
              ) : (
                <>
                  <div className="head_btn_group d-flex ms-3">
                    <Button
                      className="btn btn-md btn-primary me-md-3 me-2"
                      type="submit"
                      onClick={() => {
                        setOpenLoginModal(true);
                      }}
                    >
                      {t("login")}
                    </Button>
                    {whiteLabelData?.isRegistrationOpen ? (
                      <Button
                        className="btn btn-md btn-red"
                        onClick={() => {
                          history.push(ROUTES.REGISTER);
                        }}
                      >
                        {t("register")}
                      </Button>
                    ) : null}
                  </div>
                </>
              )}

              <button className="navbar-toggler ps-md-3" type="button">
                <span
                  className="navbar-toggler-icon"
                  // onClick={() => setExpandNav(!expandNav)}
                  onClick={toggleBodyClass}
                ></span>
              </button>
            </div>
          </div>
        </nav>
      </header>

      <div className="running-text">
        <div className="enqueued-text">
          <div className="row">
            <div className="col-md-1 col-2">
              <div className="speaker">
                {/* <span className="marquee-label text-uppercase">{t("latest-info")} :</span> */}
                <span style={{ color: 'red' }}>&#128226;</span>
              </div>

            </div>
            <div className="col-md-11 col-10">
              <div className="marquee-wrapper">
                <div className="marquee">
                  <marquee scrollamount="9">
                    {runningText?.map((item, key) => (
                      <span key={"running" + key} style={{ marginRight: "1in" }}>
                        {item?.content || ""}
                      </span>
                    ))}
                  </marquee>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {children}

      {openLoginModal && (
        <LoginModal
          open={openLoginModal}
          setOpen={setOpenLoginModal}
          toggleForgotModal={() => setOpenForgotModal(!openForgotModal)}
        />
      )}
      {openForgotModal && (
        <ForgotModal
          open={openForgotModal}
          setOpen={setOpenForgotModal}
          toggleLoginModal={() => setOpenLoginModal(!openLoginModal)}
        />
      )}
      <WelcomeModal />
    </>
  );
};

export default AppLayout;
