import { logout } from "Redux/Actions/Auth";
import { startLoading, stopLoading } from "Redux/Actions/loader";
import { CANCEL, CREATE_BANK_ACCOUNT, DELETE_BANK_ACCOUNT, EDIT_BANK_ACCOUNT, GET_ACCOUNT_BRANDS, GET_ACCOUNT_TYPES, GET_BANK_ACCOUNTS, GET_TRANSACTIONS, GET_USER_DETAILS, TRANSACTION, UPDATE_USER_DETAILS, saveBankAccounts, setUserDetails, GET_BONUS_TRANSACTIONS, GET_REFERRAL_LIST } from "Redux/Actions/user";
import { API_URLS, STATUS_CODES } from "Services/Api/Constants";
import { deleteRequest, getRequest, postRequest, putRequest } from "Shared/Axios";
import { all, put, takeEvery, takeLatest } from "redux-saga/effects";


function* getUserDetails({ callback = () => { } }) {
  try {
    yield put(startLoading());
    const apiResponse = yield getRequest({
      API: API_URLS.USER,
    });
    let { data, response } = apiResponse;
    if (response?.status == STATUS_CODES.UNAUTHORIZED) {
      yield put(logout());
    } else if (data.statusCode == STATUS_CODES.SUCCESS) {
      callback(data?.data.user);
      yield put(stopLoading());
      yield put(setUserDetails(data?.data?.user || {}));

    } else {
      // fail(data.msg);
    }
  } catch (error) {
    console.log(error, "error");
  } finally {
    yield put(stopLoading());
  }
}

function* updateUserDetails({ payload, callback = () => { } }) {
  try {
    yield put(startLoading());
    const apiResponse = yield putRequest({
      API: API_URLS.UPDATEUSER,
      DATA: payload
    });
    let { data, response } = apiResponse;

    if (response?.status == STATUS_CODES.UNAUTHORIZED) {
      yield put(logout());
    } else if (data.statusCode == STATUS_CODES.SUCCESS) {
      callback(data);
      yield put(stopLoading());
    } else {
      // fail(data.msg);
    }
  } catch (error) {
    console.log(error, "error");
  } finally {
    yield put(stopLoading());
  }
}

function* getBankAccounts({ payload, callback = () => { } }) {
  try {
    yield put(startLoading());
    let apiUrl = `${API_URLS.BANKACCOUNT}`;
    if (payload.limit) {
      apiUrl = `${apiUrl}?limit=${payload.limit}`;
    }
    if (payload.skip) {
      apiUrl = `${apiUrl}&skip=${payload.skip}`;
    }
    if (payload.isAdminAccount) {
      apiUrl = `${apiUrl}?isAdminAccount=${payload.isAdminAccount}`;
    }
    if (payload.accountBrandId) {
      apiUrl = apiUrl.includes("?") ? `${apiUrl}&accountBrandId=${payload.accountBrandId}` : `${apiUrl}?accountBrandId=${payload.accountBrandId}`;
    }
    if (payload.isListing) {
      apiUrl = apiUrl.includes("?") ? `${apiUrl}&isListing=${payload.isListing}` : `${apiUrl}?isListing=${payload.isListing}`;
    }
    const apiResponse = yield getRequest({
      API: apiUrl,
    });
    let { data, response } = apiResponse;

    if (response?.status == STATUS_CODES.UNAUTHORIZED) {
      yield put(logout());
    } else if (data.statusCode == STATUS_CODES.SUCCESS) {
      callback(data);
      yield put(stopLoading());
      if (payload?.saveAll) {
        yield put(saveBankAccounts(data.data.bankAccount));
      }
    } else {
      // fail(data.msg);
    }
  } catch (error) {
    console.log(error, "error");
  } finally {
    yield put(stopLoading());
  }
}

function* getAccountTypes({ callback = () => { } }) {
  try {
    yield put(startLoading());
    let apiUrl = `${API_URLS.ACCOUNT_TYPES}`;
    const apiResponse = yield getRequest({
      API: apiUrl,
    });
    let { data, response } = apiResponse;

    if (response?.status == STATUS_CODES.UNAUTHORIZED) {
      yield put(logout());
    } else if (data.statusCode == STATUS_CODES.SUCCESS) {
      callback(data);
      yield put(stopLoading());
    } else {
      // fail(data.msg);
    }
  } catch (error) {
    console.log(error, "error");
  } finally {
    yield put(stopLoading());
  }
}

function* getAccountBrands({ payload, callback = () => { } }) {
  try {
    yield put(startLoading());
    let apiUrl = `${API_URLS.ACCOUNT_BRANDS}?accountTypeId=${payload?.accountTypeId}`;
    const apiResponse = yield getRequest({
      API: apiUrl,
    });
    let { data, response } = apiResponse;

    if (response?.status == STATUS_CODES.UNAUTHORIZED) {
      yield put(logout());
    } else if (data.statusCode == STATUS_CODES.SUCCESS) {
      callback(data);
      yield put(stopLoading());
    } else {
      // fail(data.msg);
    }
  } catch (error) {
    console.log(error, "error");
  } finally {
    yield put(stopLoading());
  }
}

function* getTransactions({ payload, callback = () => { } }) {
  try {
    yield put(startLoading());
    let apiUrl = `${API_URLS.TRANSACTIONS}?limit=${payload.limit}&skip=${payload.skip}`
    if (payload.fromDate) {
      apiUrl = `${apiUrl}&fromDate=${payload.fromDate}`
    }
    if (payload.toDate) {
      apiUrl = `${apiUrl}&toDate=${payload.toDate}`
    }
    if (payload.transactionType) {
      apiUrl = `${apiUrl}&transactionType=${payload.transactionType}`
    }
    const apiResponse = yield getRequest({
      API: apiUrl,
    });
    let { data, response } = apiResponse;

    if (response?.status == STATUS_CODES.UNAUTHORIZED) {
      yield put(logout());
    } else if (data.statusCode == STATUS_CODES.SUCCESS) {
      callback(data);
      yield put(stopLoading());
    } else {
      // fail(data.msg);
    }
  } catch (error) {
    console.log(error, "error");
  } finally {
    yield put(stopLoading());
  }
}

function* getBonusTransactions({ payload, callback = () => { } }) {
  try {
    yield put(startLoading());
    const apiResponse = yield getRequest({
      API: API_URLS.BONUS_TRANSACTIONS,
      params: payload
    });
    let { data, response } = apiResponse;

    if (response?.status == STATUS_CODES.UNAUTHORIZED) {
      yield put(logout());
    } else if (data.statusCode == STATUS_CODES.SUCCESS) {
      callback(data);
      yield put(stopLoading());
    } else {
      // fail(data.msg);
    }
  } catch (error) {
    console.log(error, "error");
  } finally {
    yield put(stopLoading());
  }
}

function* createBankAccount({ payload, callback = () => { } }) {
  try {
    yield put(startLoading());
    const apiResponse = yield postRequest({
      API: API_URLS.BANKACCOUNT,
      DATA: payload
    });
    let { data, response } = apiResponse;

    if (response?.status == STATUS_CODES.UNAUTHORIZED) {
      yield put(logout());
    } else if (data?.statusCode == STATUS_CODES.SUCCESS) {
      callback(data);
      yield put(stopLoading());
    } else {
      callback(response?.data);
      // fail(data.msg);
    }
  } catch (error) {
    callback(error.data);
    console.log(error, "error");
  } finally {
    yield put(stopLoading());
  }
}

function* cancelTransaction({ payload, callback = () => { } }) {
  try {
    yield put(startLoading());
    const apiResponse = yield putRequest({
      API: API_URLS.CANCEL,
      DATA: payload
    });
    let { data, response } = apiResponse;
    if (response?.status == STATUS_CODES.UNAUTHORIZED) {
      yield put(logout());
    } else if (data?.statusCode == STATUS_CODES.SUCCESS) {
      callback(data);
      yield put(stopLoading());
    } else {
      callback(response?.data);
    }
  } catch (error) {
    callback(error?.data);
  } finally {
    yield put(stopLoading());
  }
}

function* editBankAccount({ payload, callback = () => { } }) {
  try {
    yield put(startLoading());
    const apiResponse = yield putRequest({
      API: API_URLS.BANKACCOUNT,
      DATA: payload
    });
    let { data, response } = apiResponse;

    if (response?.status == STATUS_CODES.UNAUTHORIZED) {
      yield put(logout());
    } else if (data?.statusCode == STATUS_CODES.SUCCESS) {
      callback(data);
      yield put(stopLoading());
    } else {
      callback(response?.data);
      // fail(data.msg);
    }
  } catch (error) {
    callback(error.data);
    console.log(error, "error");
  } finally {
    yield put(stopLoading());
  }
}

function* deleteBankAccount({ payload, callback = () => { } }) {
  try {
    yield put(startLoading());
    const apiResponse = yield deleteRequest({
      API: API_URLS.BANKACCOUNT,
      DATA: payload
    });
    let { data, response } = apiResponse;

    if (response?.status == STATUS_CODES.UNAUTHORIZED) {
      yield put(logout());
    } else if (data?.statusCode == STATUS_CODES.SUCCESS) {
      callback(data);
      yield put(stopLoading());
    } else {
      callback(response?.data);
      // fail(data.msg);
    }
  } catch (error) {
    callback(error.data);
    console.log(error, "error");
  } finally {
    yield put(stopLoading());
  }
}

function* withDrawDeposit({ payload, callback = () => { } }) {
  try {
    yield put(startLoading());
    const apiResponse = yield postRequest({
      API: API_URLS.TRANSACTION,
      DATA: payload
    });
    let { data, response } = apiResponse;
    if (response?.status == STATUS_CODES.UNAUTHORIZED) {
      yield put(logout());
    } else if (data?.statusCode == STATUS_CODES.SUCCESS) {
      callback(data);
      yield put(stopLoading());
    } else {
      // fail(data.msg);
      callback(response?.data);
    }
  } catch (error) {
    callback(error.data);
    console.log(error.data, "error");
  } finally {
    yield put(stopLoading());
  }
}

function* getReferralList({payload,callback = () => { }}) {
  try {
    yield put(startLoading());
    const apiResponse = yield getRequest({
      API: API_URLS.REFERRAL_LIST,
      params: payload
    });
    let { data , response } = apiResponse;
    
    if (response?.status == STATUS_CODES.UNAUTHORIZED) {
      yield put(logout());
    }else if (data.statusCode == STATUS_CODES.SUCCESS) {
      callback(data);
      yield put(stopLoading());
    } else {
      // fail(data.msg);
    }
  } catch (error) {
    console.log(error,"error");
  } finally {
    yield put(stopLoading());
  }
}

function* watchUser() {
  yield all([
    takeLatest(GET_USER_DETAILS, getUserDetails),
    takeLatest(UPDATE_USER_DETAILS, updateUserDetails),
    takeEvery(GET_BANK_ACCOUNTS, getBankAccounts),
    takeEvery(GET_ACCOUNT_TYPES, getAccountTypes),
    takeEvery(GET_ACCOUNT_BRANDS, getAccountBrands),
    takeLatest(CREATE_BANK_ACCOUNT, createBankAccount),
    takeLatest(EDIT_BANK_ACCOUNT, editBankAccount),
    takeLatest(DELETE_BANK_ACCOUNT, deleteBankAccount),
    takeLatest(GET_TRANSACTIONS, getTransactions),
    takeLatest(TRANSACTION, withDrawDeposit),
    takeLatest(CANCEL, cancelTransaction),
    takeLatest(GET_BONUS_TRANSACTIONS, getBonusTransactions),
    takeLatest(GET_REFERRAL_LIST, getReferralList),
  ]);
}

export default watchUser;