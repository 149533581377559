import { Button, Table, InputField } from "Components";
import { getReferralList } from "Redux/Actions/user";
import { IMAGES } from "Shared/Images";
import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useDispatch } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { indexHistory } from "Shared/Constants";
import ReactDatePicker from "react-datepicker";
import { Input } from "@mui/material";

const ReferralList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const headings = [t("no"), t("register-at"), t("last-play"), t("username"), t("total-bet"), t("total-win"), t("win-lose")];
  const [totalCount, setTotalCount] = useState([]);
  const [data, setData] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [searchKey, setSearchKey] = useState("")

  useEffect(() => {
    let payload = {
      limit: 10,
      skip: 0,
      fromDate: startDate,
      toDate: endDate,
      searchKey: searchKey
    };
    dispatch(
      getReferralList(payload, (response) => {
        setData(response?.data);
        setTotalCount(response?.data?.totalCount);
      })
    );
  }, []);

  const handleReset = () => {
    setStartDate("");
    setEndDate("");
    setSearchKey("");

    let payload = {
      limit: 10,
      skip: 0,
      fromDate: "",
      toDate: "",
      searchKey: ""
    };
    dispatch(
      getReferralList(payload, (response) => {
        setData(response?.data);
        setTotalCount(response?.data?.totalCount);
      })
    );
  };

  const handleStartDateChange = (Date) => {
    setStartDate(Date);
    setEndDate("");
  };

  const handleEndDateChange = (Date) => {
    setEndDate(Date);
  };

  const handleApply = () => {
    let payload = {
      limit: 10,
      skip: 0,
      fromDate: startDate,
      toDate: endDate,
      searchKey: searchKey
    };

    setCurrentIndex(0);
    dispatch(
      getReferralList(payload, (response) => {
        setData(response?.data);
        setTotalCount(response?.data?.totalCount);
      })
    );
  };

  const handlePageChange = ({ selected }) => {
    let payload = {
      limit: 10,
      skip: selected,
      fromDate: startDate,
      toDate: endDate,
      searchKey: searchKey
    };

    setCurrentIndex(selected);
    dispatch(
      getReferralList(payload, (response) => {
        setData(response?.data);
        setTotalCount(response?.data?.totalCount);
      })
    );
  };

  return (
    <div className="w-100 form_front pt-4">
      <div className="W-100 form_card bg-white text-start text-dark p-4">
        <div className="row align-items-end">
          <div className="col">
            <div className="form-group">
              <div className="position-relative">
                <InputField
                  className="form-control me-1"
                  label={t("username")}
                  name={"searchKey"}
                  type={"text"}
                  value={searchKey}
                  onChange={(e) => setSearchKey(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="col">
            <div className="form-group">
              <label className="mb-2">{t("start-date")}</label>
              <div className="position-relative">
                <ReactDatePicker
                  selected={startDate}
                  dateFormat={"dd/MM/yyyy"}
                  onChange={handleStartDateChange}
                  placeholderText={"dd/mm/yyyy"}
                  className="form-control"
                // showFullMonthYearPicker={true}
                />
                <em className="form_icon"><img src={IMAGES.calendar} height="13" width="13" alt="" /></em>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="form-group">
              <label className="mb-2">{t("end-date")}</label>
              <div className="position-relative">
                <ReactDatePicker
                  selected={endDate}
                  dateFormat={"dd/MM/yyyy"}
                  onChange={handleEndDateChange}
                  placeholderText={"dd/mm/yyyy"}
                  minDate={startDate}
                  maxDate={new Date()}
                  className="form-control"
                // showFullMonthYearPicker={true}
                />
                <em className="form_icon"><img src={IMAGES.calendar} height="13px" width="13px" alt="" /></em>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="form-group d-flex">
              <Button className="btn btn-md btn-primary" type="submit" onClick={handleApply}>
                {t("apply")}
              </Button>
              <button className="btn btn-md btn-transparent ms-2" onClick={handleReset}> {t("reset")}</button>
            </div>
          </div>
        </div>

        <>
          {data?.referrals?.length ? (
            <>
              <div className="table-responsive">
                <Table className="table text-dark">
                  <thead>
                    <tr>
                      <th>Total Player</th>
                      <th>Total Bet</th>
                      <th>Total Win</th>
                      <th>Total WinLose</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{data?.totalPlayer ? data?.totalPlayer : 0}</td>
                      <td>{data?.totalBet ? data?.totalBet?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0}</td>
                      <td>{data?.totalWin ? data?.totalWin?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0}</td>
                      <td>{data?.totalWinLose ? data?.totalWinLose?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0}</td>
                    </tr>
                  </tbody>
                </Table>
              </div>

              <div className="table-responsive">
                <Table className="table text-dark" headings={headings}>
                  <tbody>
                    {data.referrals.map((item, index) => (
                      <tr key={index}>
                        <td>{indexHistory(currentIndex, 10) + index}</td>
                        <td>{item?.createdAt}</td>
                        <td>{item?.updatedAt}</td>
                        <td>{item?.userName}</td>
                        <td>{item?.totalBet ? item?.totalBet?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0}</td>
                        <td>{item?.totalWin ? item?.totalWin?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0}</td>
                        <td className={item?.winLose < 0 ? "text-danger" : item?.winLose == 0 ? "" : "text-success"}>{item?.winLose ? item?.winLose?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>



              <ReactPaginate
                previousLabel="<<"
                nextLabel=">>"
                breakLabel="..."
                pageCount={Math.ceil(totalCount / 10)}
                marginPagesDisplayed={3}
                onPageChange={handlePageChange}
                containerClassName="pagination justify-content-center mt-4"
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakLinkClassName="page-link"
                breakClassName="page-item"
                activeClassName="active"
              />
            </>
          ) : (
            <div className="p-5 d-flex flex-column justify-content-center text-center no-result">
              <em><img src={IMAGES.empty} alt="" width="44" /></em>
              <span className="h5 mt-3">{t("no-trans")}</span>
            </div>
          )}
        </>
      </div>
    </div>
  );
};

export default ReferralList;
